import config from './config'
import axios from './axiosConf'

const API = config.APIAuthor

const create = async (dataAuthor) => {
  const { data } = await axios.post(API, dataAuthor)
  return data
}

const searchAuthor = async (query) => {
  const { data } = await axios.get(`${API}/search?term=${query}`)
  return data
}

export default {
  create,
  searchAuthor
}
