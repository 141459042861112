import config from './config'
import axios from './axiosConf'

const API = config.APIAdmin
const APIOrigin = config.API

const removeCharExpedients = async () => {
  return await axios.post(`${API}/remove-enter-char-expedientes`, null, { timeout: 1000 * 60 * 30 })
}

const migrateHightlightFormInLegis = async () => {
  return await axios.post(`${API}/migrate-highlight-db`, null, { timeout: 1000 * 60 * 30 })
}

const sendNotification = async (data) => {
  return await axios.post(`${APIOrigin}/notification/send-to-all`, data)
}

const saveAppInfoVersion = async (info) => {
  return await axios.post(`${APIOrigin}/administrate/config-version-app`, info)
}
const getAppInfoVersion = async () => {
  const { data } = await axios.get(`${APIOrigin}/administrate/app-version`)
  return data
}
const getBankInfo = async () => {
  const { data } = await axios.get(`${APIOrigin}/administrate/bank-info-exchange-rate-dollar`)
  return data
}
const saveBankInfo = async (info) => {
  return await axios.post(`${APIOrigin}/administrate/save-bank-info`, info)
}

/** sync user */
const syncDataUser = async (body) => {
  const { data } = await axios.post(`${APIOrigin}/administrate/sync-data-user-bt-fire-db`, body, { timeout: 1000 * 60 * 60 * 5 })
  return data
}

/**jobs */
const runCronJobs = () => {
  return axios.post(`${APIOrigin}/administrate/run-cron-jobs-now`)
}

/**redis */
const deleteARedisKeyContention = (key) => {
  return axios.post(`${APIOrigin}/admin-redis/delete-from-key`, {
    key
  })
}

/** test request */
const requestAppleTestNoti = () => {
  return axios.post(`${APIOrigin}/payment/request-apple-store/test-notification`)
}
const requestZohoToken = async (eData) => {
  const { data } = await axios.post(`${APIOrigin}/administrate/request-zoho-oauth-token`, eData)
  return data
}

/** Offers */
const remoteConfigs = async () => {
  const { data } = await axios.get(`${APIOrigin}/administrate/remote-configs`)
  return data
}
const saveAnOffer = async (offer) => {
  const { data } = await axios.post(`${APIOrigin}/administrate/save-offer`, offer)
  return data
}

export default {
  removeCharExpedients,
  migrateHightlightFormInLegis,
  sendNotification,
  saveAppInfoVersion,
  getAppInfoVersion,
  runCronJobs,
  syncDataUser,
  getBankInfo,
  saveBankInfo,
  deleteARedisKeyContention,
  requestAppleTestNoti,
  requestZohoToken,
  saveAnOffer,
  remoteConfigs,
}