import config from './config'
import axios from './axiosConf'

const API = config.APILegis

const changeIdx = async ({ indices, articles, codeId }) => {
  const { data } = await axios.put(`${API}/code/idxs/update/${codeId}`, { indices, articles })
  return data
}

const pubAudioStatus = async ({ pubAud, _id, sAud }) => {
  const { data } = await axios.put(`${API}/codigo/${_id}`, { pubAud, audio: pubAud, sAud })
  return data
}

const getSharedCodes = async () => {
  const { data } = await axios.get(`${API}/search/shared/codes`)
  return data
}
const freeSharedCode = async ({ free, code }) => {
  const { data } = await axios.put(`${API}/change-free-shared-code`, { codeId: code._id, free })
  return data
}

const addPriceSharedCode = async (dataPrice) => {
  const { data } = await axios.post(`${API}/price/shared-code`, dataPrice)
  return data
}

const getSharedCodeContent = async (uid, slug) => {
  const { data } = await axios.get(`${API}/shared/${uid}/${slug}`)
  return data
}

const getSharedGroups = async () => {
  const { data } = await axios.get(`${API}/shared-groups`)
  return data
}

const createSharedGroup = async (info) => {
  const { data } = await axios.post(`${API}/shared-group`, info)
  return data
}

const updateSharedGroup = async (info) => {
  const { data } = await axios.put(`${API}/shared-group/${info._id}`, info)
  return data
}

const addGroupSharedCode = async ({ groupId, sharedCodeId }) => {
  const { data } = await axios.put(`${API}/shared-group/add-code-group`, { groupId, sharedCodeId })
  return data
}

const removeGroupSharedCode = async ({ groupId, sharedCodeId }) => {
  const { data } = await axios.delete(`${API}/shared-group/add-code-group/${groupId}/${sharedCodeId}`)
  return data
}

export default {
  changeIdx,
  pubAudioStatus,
  getSharedCodes,
  freeSharedCode,
  addPriceSharedCode,
  getSharedCodeContent,
  getSharedGroups,
  createSharedGroup,
  updateSharedGroup,
  addGroupSharedCode,
  removeGroupSharedCode,
}
