<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    :mini-variant="minVariantNavigation"
    dark
    class="colortheme"
    :mobile-breakpoint="959"
  >
    <template v-slot:prepend>
      <router-link :to="{ name: 'home' }" class="astily">
        <v-list-item>
          <img :src="iconSrc" width="30" alt="user" />
          <span class="ml-2 title">Abogher</span>
        </v-list-item>
      </router-link>
      <v-divider></v-divider>
    </template>

    <v-list dense nav class="py-0">
      <template v-for="item in itemsNavigate">
        <v-list-item v-if="!item.group" :key="item.title" :to="item.route" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-else
          :key="item.title"
          :prepend-icon="item.icon"
          :color="item.colorIcon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="child in item.children"
            :key="child.title"
            :to="child.route"
          >
            <v-list-item-content>
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon :color="child.colorIcon">{{ child.icon }} </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>

    <template v-slot:append>
      <v-divider></v-divider>
      <v-list-item>
        <v-btn icon @click="minimizaNav(!minVariantNavigation)">
          <v-icon
            v-text="
              minVariantNavigation ? 'mdi-chevron-right' : 'mdi-chevron-left'
            "
          ></v-icon>
        </v-btn>
        <span>v1.0.1</span>
        <v-icon>mdi-copyright</v-icon>
        <v-list-item-title class="overline"
          >2022 Abogher Admin</v-list-item-title
        >
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    drawer: true,
    mini: false,
    items: [
      { title: "Inicio", icon: "mdi-home", route: "/" },
      {
        title: "Jurisprudencia",
        icon: "mdi-pillar",
        route: { name: "jurisprudencia" },
      },
      {
        title: "Legislación",
        icon: "mdi-scale-balance",
        group: true,
        children: [
          {
            title: "Lista general",
            icon: "mdi-scale-balance",
            route: { name: "legislation" },
          },
          {
            title: "Compartidos",
            icon: "mdi-folder-account",
            route: { name: "sharedLegis" },
          },
        ],
      },
      {
        title: "Minutas",
        icon: "mdi-file-document-multiple-outline",
        route: { name: "bills" },
      },
      {
        title: "Usuarios",
        icon: "mdi-account-box-multiple",
        route: { name: "Users" },
      },
      {
        title: "Configuracones admin",
        icon: "mdi-cog-box",
        superAdmin: true,
        route: { name: "app-version" },
      },
      {
        title: "Transferencias bancarias",
        icon: "mdi-bank-transfer",
        route: { name: "vouchers-attend" },
      },
      {
        title: "Notificaciones",
        icon: "mdi-bell",
        route: { name: "notification-admin" },
      },
      {
        title: "Ofertas",
        icon: "mdi-offer",
        route: { name: "admin-offers" },
      },
      // {
      //   title: "Libros",
      //   icon: "mdi-book-open-page-variant",
      //   route: { name: "books" },
      // }
    ],
  }),
  computed: {
    ...mapState(["minVariantNavigation"]),
    iconSrc() {
      return require("../../../public/abogherlogo.png");
    },
    itemsNavigate() {
      if (this.$isSuperAdmin) {
        return this.items;
      }
      return this.items.filter((item) => !item.superAdmin);
    },
  },
  methods: {
    ...mapMutations(["minimizaNav"]),
  },
};
</script>
