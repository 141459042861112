import config from './config'
import axios from './axiosConf'

const API = config.APIJuris

/**
 * Topics
 */
const getTotalTopics = async (ambit) => {
  const { data } = await axios.get(`${API}/count/temas/admin?amb=${ambit}`)
  return data
}
const getList = async (perpage, from, ambit) => {
  const { data } = await axios.get(`${API}/temas/admin?perpage=${perpage}&from=${from}&amb=${ambit}`)
  return data
}
const getContentTopic = async (id, entityExpedientes) => {
  const query = entityExpedientes ? `?entity=${entityExpedientes}` : ''
  const { data } = await axios.get(`${API}/tema/${id}/administrate${query}`)
  return data
}
const createTopic = async (dataJuris) => {
  const { data } = await axios.post(`${API}/tema`, dataJuris)
  return data
}
const updateTopic = async (idTopic, dataJuris) => {
  const { data } = await axios.put(`${API}/tema/${idTopic}`, dataJuris)
  return data
}
const deleteTopic = async (idTopic) => {
  const { data } = await axios.delete(`${API}/tema/${idTopic}`)
  return data
}
/**
 * Subtopics
 */
const createSubtopic = async (idTopic, subtopic) => {
  const { data } = await axios.post(`${API}/subtema/${idTopic}`, subtopic)
  return data
}
const updateSubtopic = async (idTopic, idSubtopic, subtopic) => {
  const { data } = await axios.put(`${API}/subtema/${idTopic}/${idSubtopic}`, subtopic)
  return data
}
const deleteSubtopic = async (idTopic, idSubtopic) => {
  const { data } = await axios.delete(`${API}/subtema/${idTopic}/${idSubtopic}`)
  return data
}

/**
 * Expedients
 */
const createExpedient = async (idTopic, expedient) => {
  const { data } = await axios.post(`${API}/expediente/${idTopic}`, expedient)
  return data
}
const updateExpediet = async (idExpedient, expedient) => {
  const { data } = await axios.put(`${API}/expediente/${idExpedient}`, expedient)
  return data
}
const deleteExpedient = async (idExpedient) => {
  const { data } = await axios.delete(`${API}/expediente/${idExpedient}`)
  return data
}
const getContentExpedient = async (id) => {
  // const { data } = await axios.get(`${API}/expediente/${id}/con`)
  const { data } = await axios({
    method: 'get',
    url: `${API}/expediente/${id}/con`,
    headers: { 'Cache-Control': 'no-store' }
  })
  return data
}
const changeModeExpedients = async (expedientId, topicId, toFree) => {
  const { data } = await axios.put(`${API}/expedient/mode`, {
    expedientId,
    topicId,
    toFree,
  })
  return data
}

export default {
  getTotalTopics,
  getList,
  getContentTopic,
  createTopic,
  updateTopic,
  deleteTopic,
  createSubtopic,
  updateSubtopic,
  deleteSubtopic,
  createExpedient,
  updateExpediet,
  deleteExpedient,
  getContentExpedient,
  changeModeExpedients,
}
