import repository from '@/repositories/RepoUser'

export default {
  namespaced: true,
  state: () => ({
    listUsers: []
  }),

  mutations: {
    setUsersLote (state, { lote, reset = false }) {
      if (reset) {
        state.listUsers = []
      }
      state.listUsers = [...state.listUsers, ...lote]
    },
    deleteUser (state, user) {
      const index = state.listUsers.findIndex(u => u.uid === user.uid)
      if (index !== -1) {
        state.listUsers.splice(index, 1)
      }
    },
    setExtraDataAUser (_, { user, extra }) {
      user.extraData = extra
    }
  },

  actions: {
    async _deleteUser ({ commit }, user) {
      await repository.deleteUser(user.uid)
      commit('deleteUser', user)
    },
    async _getExtraDataAUser ({ commit }, user) {
      const extra = await repository.getExtraDataAUser(user.uid)
      commit('setExtraDataAUser', { user, extra })
    }
  }
}