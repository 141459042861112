import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB5sCDpDysDhOmruYe2v9q1ilCA7serNL8",
  authDomain: "abok-264303.firebaseapp.com",
  databaseURL: "https://abok-264303.firebaseio.com",
  projectId: "abok-264303",
  storageBucket: "abok-264303.appspot.com",
  messagingSenderId: "866698231632",
  appId: "1:866698231632:web:5c5c895c38ba73f4ecf655",
  measurementId: "G-XH8M179SH4"
}

  // // Configuration test
const firebaseConfigTest = {
  apiKey: "AIzaSyCE-BY_5ewNInzjM0QqjaIVe9dIrCYyrZg",
  authDomain: "abok-test.firebaseapp.com",
  databaseURL: "https://abok-test.firebaseio.com",
  projectId: "abok-test",
  storageBucket: "abok-test.appspot.com",
  messagingSenderId: "350307392584",
  appId: "1:350307392584:web:ffed6e9df885245b8ccc7d",
  measurementId: "G-6HK712H0BW"
}

const inDev = process.env.NODE_ENV === 'development'
  // Initialize Firebase
firebase.initializeApp(inDev ? firebaseConfigTest : firebaseConfig);
//Only for local test
// if (inDev) {
//   firebase.app().functions('us-east1').useEmulator('localhost', 5001)
// }

//Enable cache for persistent
// firebase.firestore().enablePersistence()
// .catch(function(err) {
//   if (err.code == 'failed-precondition') {
//     console.log("solo se acepta en una pestaña la cache")
//   } else if (err.code == 'unimplemented') {
//     console.log("navegador no soportado para la cache")
//   }
// })

export default firebase
