import Vue from 'vue'
import axios from 'axios'

let intervalTokenGet = null

async function setIdToAxios () {
  try {
    const token = await Vue.prototype.$firebase.auth().currentUser.getIdToken(true)
    axios.defaults.headers.common.Authorization = token
    if (!intervalTokenGet) {
      intervalTokenGet = setInterval(() => setIdToAxios(), 1000 * 60 * 30)
      console.log('interval runed')
    }
    return token
  } catch (error) {
    console.error(error)
  }
}

axios.interceptors.request.use(async function (config) {
  if (!config.headers.common.Authorization) {
    config.headers.common.Authorization = await setIdToAxios()
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.defaults.headers.get['Cache-Control'] = 'no-cache'

export default axios
