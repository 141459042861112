<template>
  <v-app-bar
    app
    class="colorfondo"
    elevate-on-scroll
    dense
    clipped-right
  >
    <v-app-bar-nav-icon
      v-show="!drawer"
      class="d-md-none"
      @click="drawer = !drawer"
    />

    <v-spacer></v-spacer>

    <v-menu bottom right offset-y>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon>
          <v-avatar size="35">
            <img :src="avatarUser" alt="user" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item three-line class="d-flex justify-center">
          <v-list-item-avatar size="80">
            <img :src="avatarUser" alt="user" />
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="d-flex justify-center">
              {{ user.displayName || "Usuario" }}
            </v-list-item-title>
            <v-list-item-subtitle class="d-flex justify-center">
              {{ user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action class="mx-auto">
            <v-btn
              rounded
              outlined
              class="px-4"
              :to="{ name: 'profile' }"
            >
              Mi perfil
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon> <v-icon>mdi-help-box</v-icon> </v-list-item-icon>
          <v-list-item-title>Ayuda</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon> <v-icon>mdi-nut</v-icon> </v-list-item-icon>
          <v-list-item-title>Configuraciones</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="salir">
          <v-list-item-icon>
            <v-icon color="red">mdi-door-closed</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Salir</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    drawer: true
  }),
  computed: {
    ...mapState(['user']),
    avatarUser () {
      if (this.user.photoURL) return this.user.photoURL
      return require('../../assets/user-default-small.png')
    }
  },
  methods: {
    salir() {
      this.$firebase
        .auth()
        .signOut()
        .then((res) => {
          this.$router.replace({ name: "login" });
        })
        .catch((err) => {
          alert("error ");
          console.error(err);
        });
    }
  }
}
</script>
