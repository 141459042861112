import config from './config'
import axios from './axiosConf'

const API = config.APIPayment
const APIO = config.API

const getPlans = async () => {
  const { data } = await axios.get(`${API}/all/plans`)
  return data
}

const saveNewPlan = async (plan) => {
  const { data } = await axios.post(`${API}/create/new/plan`, plan)
  return data
}

const deleteAPlan = async (_id) => {
  const { data } = await axios.delete(`${API}/delete/aplan/${_id}`)
  return data
}

const getASuscriptionsUser = async (uid) => {
  const { data } = await axios.get(`${API}/suuscriptions/user-sp/${uid}`)
  return data
}

const changeAccountType = async (dataToChange) => {
  const { data } = await axios.post(`${API}/manual/change-accountype`, dataToChange, { timeout: 1000 * 60 * 30 })
  return data
}

/**List vouchesr */
const getAllVouchers = async (filter = '') => {
  const { data } = await axios.get(`${API}/list-vouchers?filter=${filter}`)
  return data
}
const makeVoucherAttended = async (voucherId) => {
  const { data } = await axios.post(`${API}/voucher-attended`, { voucherId })
  return data
}
const deleteAVoucher = async (voucherId) => {
  const { data } = await axios.delete(`${API}/voucher-delete/${voucherId}`)
  return data
}
/** Orders */
const completeAnOrder = async (voucher) => {
  const { data } = await axios.post(`${API}/orders/to-complete`, {
    orderId: voucher.order,
    uid: voucher.user
  }, { timeout: 10000 })
  return data
}
export default {
  getPlans,
  saveNewPlan,
  deleteAPlan,
  getASuscriptionsUser,
  changeAccountType,
  getAllVouchers,
  makeVoucherAttended,
  completeAnOrder,
  deleteAVoucher,
}
