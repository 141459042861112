<template>
  <div>
    <AppBarCustom />
    <NavigationDrawerCustom />

    <!-- Ventana interna principal -->
    <div class="mx-2 mx-sm-4">
      <router-view></router-view>
    </div>
    <!-- FIn ventana interna -->

    <!-- Alertas snack -->
    <v-snackbar
      v-model="notification"
      :color="colorNoti"
      :timeout="ttl"
    >
      {{ textNoti }}

      <template v-slot:action="{ attrs }">
        <v-btn right icon @click="notification = false" v-bind="attrs">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import NavigationDrawerCustom from './layouts/NavigationDrawerCustom.vue'
import AppBarCustom from './layouts/AppBarCustom.vue'

export default {
  components: {
    NavigationDrawerCustom,
    AppBarCustom
  },
  data: () => ({
    notification: false,
    textNoti: "",
    colorNoti: "",
    ttl: 5000
  }),
  mounted() {
    this.$root.$on("notifyAction", (data) => {
      this.notify(data.text, data.color, data.ttl)
    })
  },
  methods: {
    notify(text, color = "success", ttl = 5000) {
      this.notification = true;
      this.textNoti = text;
      this.colorNoti = color;
      this.ttl = ttl
    },
  }
};
</script>
