import repository from '@/repositories/RepoJuris'

export default {
  namespaced: true,
  state: {
    listaJuris: [
        [],[],[],[]
    ],
  },
  mutations: {
    setAnJurisAmbitItems(state, { ambit = 0, items }) {
      state.listaJuris[ambit] = items
    }
  },
  actions: {
    async _getTotalTopics (_, ambit) {
      const { count } = await repository.getTotalTopics(ambit)
      return count
    },
    async _getTopics (_, { perpage, from, ambit }) {
      const { temas } = await repository.getList(perpage, from, ambit)
      return temas
    },
    async _getContentTopic (_, { entityExpedientes, id }) {
      const { doc } = await repository.getContentTopic(id, entityExpedientes)
      return doc
    },
    async _createTopic (_, data) {
      const { _id, ord } = await repository.createTopic(data)
      return { _id, ord }
    },
    async _updateTopic (_, { id, data }) {
      await repository.updateTopic(id, data)
    },
    async _deleteTopic (_, idTopic) {
      await repository.deleteTopic(idTopic)
    },
    async _createSubtopic (_, { idTopic, subtopic }) {
      const { _id } = await repository.createSubtopic(idTopic, subtopic)
      return _id
    },
    async _updateSubtopic (_, { idTopic, idSubtopic, subtopic }) {
      await repository.updateSubtopic(idTopic, idSubtopic, subtopic)
    },
    async _deleteSubtopic (_,{ idTopic, idSubtopic }) {
      await repository.deleteSubtopic(idTopic, idSubtopic)
    }
  }
}