import Vue from 'vue'
import API from '../repositories/RepoLegis'

export default {
    state: {
        totalBooks: -1,
        nChangesIdxs: 0,
        book: {
            title: '',
            decreto: ''
        },
        searchInListBooks: '',
        article: {},
        listBooks: [],
        sharedCodes: [],
        listContentBook: [],
        awaitListContentBook: [],
        indice: [],
        considerandoDecreto:'',
        disposicionesFinales:'',
        showIdxInArticles: false,
        readyToSaveNewIdx: false,
        fromSendNotificationShared: false,
        sharedCodeToSendNotification: {}
    },
    mutations: {
        setSearchTerm (state, term) {
            state.searchInListBooks = term
        },
        mutateConsiderandoDecreto(state,content){
            state.considerandoDecreto = content
        },
        mutateDisposicionesFinales(state,content){
            state.disposicionesFinales = content
        },
        mutateTotalBooks (state, total){
            state.totalBooks = total
        },
        mutateBook (state, book) {
            state.book = Object.assign(state.book, book)
        },
        defaultBook (state) {
            state.book = {title: '', decreto: ''}
            state.showIdxInArticles = false
            state.readyToSaveNewIdx = false
            state.nChangesIdxs = 0
        },
        mutateAddListBook (state, book) {
            state.listBooks.unshift(book)
        },
        defaultListBook (state) {
            state.listBooks = []
        },
        mutateAddListContentBook (state, content) {
            state.listContentBook.push(content)
        },
        mutateChangeItemInListContentBook (state, content) {
            const {index, num=1 } = content
            delete content.index
            delete content.num
            state.listContentBook.splice(index,num,content)
        },
        delteAnItemToList (state ) {
            state.listContentBook.pop()
        },
        deleteAnItemToIndex (state) {
            state.indice.pop()
        },
        defaultListContentBook (state) {
            state.listContentBook = []
            state.awaitListContentBook = []
        },
        updateModeCode (state, { _id, mode, nArticles }) {
            const index = state.listBooks.findIndex((book) => String(book._id) === String(_id))
            if (index !== -1) {
                state.listBooks[index].mode = mode
                state.listBooks[index].publicArticles = nArticles
            }
        },
        mutateArticle (state,item){
            state.article = Object.assign({index:item.index},item.article)
        },
        mutateAddIndice (state, indice){
            state.indice.push(indice)
        },
        ordListByIdx (state, item) {
            state[item] = state[item].sort((a,b) => {
                if(a.idx > b.idx) return 1
                if(a.idx < b.idx) return -1
                return 0
            })
        },
        defaultIndice (state){
            state.indice = []
        },
        changeStatusItemLegis (state, { status, item }) {
            state[item] = status
        },
        changeIdxContentCode (state, { _id, newIdx, oldIdx, next }) {
            const index = state.listContentBook.findIndex(code => code._id === _id)
            const nextIndex = index + next
            if (index !== -1) {
                state.listContentBook[index].idx = newIdx
                state.listContentBook[nextIndex].idx = oldIdx
                Vue.set(state.listContentBook[index], 'cngIdx', true)
                Vue.set(state.listContentBook[nextIndex], 'cngIdx', true)
                state.readyToSaveNewIdx = true
            }
        },
        forceChageIdxByIndexContentCode (state) {
            state.nChangesIdxs = 0
            state.listContentBook.forEach((content, index) => {
                if (Number(content.idx) !== index) {
                    content.idx = index
                    Vue.set(content, 'cngIdx', true)
                    state.readyToSaveNewIdx = true
                    state.nChangesIdxs++
                }
            })
        },
        setPubAudStateToCode (_, { code, status }) {
            Vue.set(code, 'pubAud', status)
        },
        fillInSharedCodes (state, { content }) {
            state.sharedCodes = content
        },
        freeChangeStatusCode (_, { code, free }) {
            Vue.set(code, 'free', free)
        },
        addGroupToCode(_, {code, groupId}) {
            if (!code.groups) {
                Vue.set(code, 'groups', [])
            }
            code.groups.push(groupId)
        },
        removeGroupToCode(_, {code, groupId}) {
            const index = code.groups.findIndex(gpi => gpi === groupId)
            if (index !== -1) {
                code.groups.splice(index, 1)
            }
        }
    },

    actions: {
        addNewArticleToList({ commit },item){
            return new Promise((resolve, reject) => {
                commit('mutateAddListContentBook',item)
                resolve()
            })
        },
        _changeIdxContentCode ({ commit }, data) {
            commit('changeIdxContentCode', data)
            commit('ordListByIdx', 'listContentBook')
        },
        async _saveNewIdxs ({ state, commit }) {
            const idxs = state.listContentBook
                .filter(article => article.cngIdx)
                .map(article => ({
                    _id: article._id,
                    idx: article.idx,
                    isArt: article.isArt
                }))
            const indices = []
            const articles = []
            idxs.forEach(article => {
                if (article.isArt) {
                    articles.push({ _id: article._id, idx: article.idx })
                } else {
                    indices.push({ _id: article._id, idx: article.idx })
                }
            })
            await API.changeIdx({ indices, articles, codeId: state.book._id })
            commit('changeStatusItemLegis', { item: 'readyToSaveNewIdx', status: false })
        },
        async _getSharedCodes ({ commit }) {
            const { publishedCodes } = await API.getSharedCodes()
            commit('fillInSharedCodes', { content: publishedCodes })
        },
        async _freeSharedCode ({ commit }, { free, code }) {
            await API.freeSharedCode({ free, code })
            commit('freeChangeStatusCode', { code, free })
        },
        async _addCodeToGroup({ commit }, { code, groupId }) {
            await API.addGroupSharedCode({ groupId: groupId, sharedCodeId: code._id })
            commit('addGroupToCode', { code, groupId })
        },
        async _removeCodeToGroup({ commit }, { code, groupId }) {
            await API.removeGroupSharedCode({ groupId: groupId, sharedCodeId: code._id })
            commit('removeGroupToCode', { code, groupId })
        }
    }
}