const API = process.env.NODE_ENV === 'development' ? 'http://localhost:5500/api' : 'https://apiv.abogher.com/api'
const APIAuthor = `${API}/book/author`
const APIJuris = `${API}/jurisprudencia`
const APILegis = `${API}/legislacion`
const APIUser = `${API}/user`
const APIAdmin = `${API}/administrate/options`
const APIBill = `${API}/bill`
const APIPayment = `${API}/payment`

export default {
  API,
  APIAuthor,
  APIJuris,
  APILegis,
  APIUser,
  APIAdmin,
  APIBill,
  APIPayment
}
