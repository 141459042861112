import API from '../../repositories/RepoAuthor'

const state = () => ({
})

const mutations = {
}

const actions = {
  async _createAuthor (_, dataAuthor) {
    const { _id } = await API.create(dataAuthor)
    return _id
  },
  async _searchAuthor (_, query) {
    const { authors } = await API.searchAuthor(query)
    return authors
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
