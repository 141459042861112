import config from './config'
import axios from './axiosConf'

const API = config.APIUser

const deleteUser = async (uid) => {
  const { data } = await axios.delete(`${API}/admin/delete/${uid}`)
  return data
}

const getExtraDataAUser = async (uid) => {
  const { data } = await axios.get(`${API}/admin/user-extra/${uid}`)
  return data
}

export default {
  deleteUser,
  getExtraDataAUser
}
