import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import firebase from '@/plugins/firebase'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import axios from 'axios'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

dayjs.locale('es')

Vue.use(CKEditor)
Vue.config.productionTip = false

let app = ''

Vue.prototype.$firebase = firebase
Vue.prototype.$http = axios
Vue.prototype.$dayjs = dayjs
Vue.prototype.$isSuperAdmin = false
Vue.prototype.$debounce = function (func, wait, immediate) {
  let timeout
  return function () {
    const context = this; const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

firebase.auth().onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
  if (user) {
    //inicializar usuario
    Vue.prototype.$isSuperAdmin = user.email === 'mikevi77@gmail.com'
    store.dispatch('initAuth')
  }else{
    store.commit('logout')
  }
  Vue.prototype.$notify = (text, color = 'success', ttl = 5000) => {
    app.$root.$emit('notifyAction',{text, color, ttl})
  }
})
