import Vue from 'vue'
import repository from '@/repositories/RepoJuris'
import { makeSearchableText } from '@/helpers/convertString'

export default {
  namespaced: true,
  state: {
    expedients: []
  },
  mutations: {
    update (state, expedient){
      const index = state.expedients.findIndex(exp => exp._id === expedient._id)
      if (index !== -1) {
        state.expedients.splice(index, 1, expedient)
      }
    },
    setContent (state, content) {
      const index = state.expedients.findIndex(exp => exp._id === content._id)
      if (index !== -1) {
        Vue.set(state.expedients[index], 'content', content.content)
        Vue.set(state.expedients[index], 'date', content.date)
        Vue.set(state.expedients[index], 'entity', content.entity)
        Vue.set(state.expedients[index], 'title', content.title)
      }
    },
    create (state, exp) {
      state.expedients.push(exp)
    },
    fillIn (state, list) {
      state.expedients = list.map(exp => {
        const forsearch = makeSearchableText(exp.summary)
        return {...exp, forsearch}
      })
      state.expedients.sort((a, b) => a.ord - b.ord)
    },
    delete (state, expedient) {
      const index = state.expedients.findIndex(exp => exp._id === expedient._id)
      if (index !== -1) {
        state.expedients.splice(index, 1)
      }
    },
    changeMode (_, { item, mode }) {
      if (!item) {
        return
      }
      item.mode = mode
    },
    allExpedientsMode (state, mode) {
      state.expedients.forEach((exp) => {
        exp.mode = mode
      })
    }
  },
  actions: {
    async _createExpedient (_, { idTopic, expedient }) {
      const { _id } = await repository.createExpedient(idTopic, expedient)
      return _id
    },
    async _updateExpediet (_, { id, expedient }) {
      await repository.updateExpediet(id, expedient)
    },
    async _deleteExpedient (_, idExpedient) {
      await repository.deleteExpedient(idExpedient)
    },
    async _getContentExpedient ({ commit }, id) {
      const { doc } = await repository.getContentExpedient(id)
      commit('setContent', doc)
    }
  }
}