import Vue from 'vue'
import API from '../../repositories/RepoSuscription'
import APIAdmin from '@/repositories/RepoAdmin'
import APILegis from '../../repositories/RepoLegis'

const state = () => ({
  plans: [],
  vouchers: [],
  changeUSDValue: 0
})

const mutations = {
  fillIn (state, { list, content }) {
    state[list] = content
  },
  pushInList (state, { list, content }) {
    state[list].unshift(content)
  },
  deleteAPlan (state, _id) {
    const index = state.plans.findIndex((pl) => pl._id === _id)
    if (index !== -1) {
      state.plans.splice(index, 1)
    }
  },
  setSuscriptionsUser(_, { user, suscriptions }) {
    Vue.set(user, 'suscriptions', suscriptions)
  },
  changeAccountTypeManual (_, { user, typeTo, cancelSus, suscription }) {
    user.customClaims.accountType = typeTo
    if (cancelSus) {
      for (let index = 0; index < user.suscriptions.length; index++) {
        user.suscriptions[index].status = 'CANCELLED'
        user.suscriptions[index].isActive = false
      }
    }
    if (suscription && typeTo !== 'free') {
      if (user.suscription === undefined) {
        Vue.set(user, 'suscriptions', [suscription])
      } else {
        user.suscriptions.push(suscription)
      }
    }
    // user.customClaims.finishAt = finishAt
  },
  voucherAttended (_, voucher) {
    voucher.attended = true
  },
  changePriceCode (_, { code, price, discount }) {
    Vue.set(code, 'price', price)
    Vue.set(code, 'discount', discount)
  },
  removeAVoucher (state, voucherId) {
    const index = state.vouchers.findIndex(vh => vh._id === voucherId)
    if (index !== -1) {
      state.vouchers.splice(index, 1)
    }
  }
}

const actions = {
  async _getPlans ({ commit }) {
    const { plans } = await API.getPlans()
    commit('fillIn', { list: 'plans', content: plans })
  },
  async _saveNewPlan ({ commit }, plan) {
    const { _id } = await API.saveNewPlan(plan)
    commit('pushInList', { list: 'plans', content: {...plan, _id} })
  },
  async _deleteAPlan ({ commit }, _id) {
    await API.deleteAPlan(_id)
    commit('deleteAPlan', _id)
  },
  async _getASuscriptionsUser ({ commit }, { user }) {
    if (user.suscriptions) {
      return
    }
    const { suscriptions = [] } = await API.getASuscriptionsUser(user.uid)
    commit('setSuscriptionsUser', { user, suscriptions })
  },
  async _changeAccountType ({ commit }, { user, typeTo, createSus, cancelSus, finishAt, resetFreeTrialUse }) {
    const susIds = []
    user.suscriptions.forEach(sus => {
      susIds.push(sus.susId)
    })
    const { suscription } = await API.changeAccountType({typeTo, susIds, uid: user.uid, createSus, cancelSus, finishAt, resetFreeTrialUse})
    commit('changeAccountTypeManual', { user, typeTo, cancelSus, suscription })
  },
  async _getAllVouchers ({ commit, state }, { filter, /*force = false*/ }) {
    // if (state.vouchers.length > 0 && !force) {
    //   return
    // }
    const { vouchers } = await API.getAllVouchers(filter)
    commit('fillIn', { list: 'vouchers', content: vouchers })
  },
  async _makeVoucherAttended ({ commit }, { voucher }) {
    await API.makeVoucherAttended(voucher._id)
    commit('voucherAttended', voucher)
  },
  async _completeAnOrder ({ commit }, { voucher }) {
    await API.completeAnOrder(voucher)
    await API.makeVoucherAttended(voucher._id)
    commit('voucherAttended', voucher)
  },
  async _addPriceSharedCode({ commit }, { code, price, discount, isMain }) {
    await APILegis.addPriceSharedCode({ price, discount, codeId: code._id, isMain })
    commit('changePriceCode', { code, price, discount })
  },
  async _getChangeUSDValue ({ commit, state }) {
    if (state.changeUSDValue) {
      return state.changeUSDValue
    }
    const { exchangeRateDollar } = await APIAdmin.getBankInfo()
    commit('fillIn', { list: 'changeUSDValue', content: exchangeRateDollar })
    return exchangeRateDollar
  },
  async _deleteAVoucher ({ commit }, voucherId) {
    await API.deleteAVoucher(voucherId)
    commit('removeAVoucher', voucherId)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
