import config from './config'
import axios from './axiosConf'

const API = config.APIBill
const APIO = config.API

const createBill = async (bill) => {
  const { data } = await axios.post(API, bill)
  return data
}
const updateBill = async ({ title, tags, _id }) => {
  const { data } = await axios.put(`${API}/${_id}`, { title, tags })
  return data
}
const completeBillList = async () => {
  const { data } = await axios.get(`${API}/all-list`)
  return data
}
const changeModeBill = async ({ bill, mode, notificate = false, notification }) => {
  const { data } = await axios.put(`${API}/mode/${bill._id}`, { mode, notificate, notification })
  return data
}
const getFullContent = async ({ slug }) => {
  const { data } = await axios.get(`${API}/find/${slug}/full`)
  return data
}
const saveBillContent = async ({ _id, content }) => {
  const { data } = await axios.put(`${API}/${_id}`, { content })
  return data
}

/** Tagas bill */
const createTag = async ({ tag }) => {
  const { data } = await axios.post(`${APIO}/administrate/tag/0`, { tag })
  return data
}
const getTags = async () => {
  const { data } = await axios.get(`${APIO}/administrate/tags/0`)
  return data
}
const updateTag = async ({ tagId, tag }) => {
  const { data } = await axios.put(`${APIO}/administrate/tag/0/${tagId}`, { tag })
  return data
}
const removeTag = async ({ tagId }) => {
  const { data } = await axios.delete(`${APIO}/administrate/tag/0/${tagId}`)
  return data
}

export default {
  createBill,
  updateBill,
  completeBillList,
  changeModeBill,
  getFullContent,
  saveBillContent,
  createTag,
  getTags,
  updateTag,
  removeTag
}
