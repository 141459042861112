import Vue from 'vue'
import API from '../../repositories/RepoBill'
const state = () => ({
  colors: ['blue', 'green', 'purple', 'indigo', 'cyan', 'teal', 'orange', 'red', 'yellow', 'pink', 'blue-grey'],
  listBills: [],
  tags: [
    { header: 'Seleccionar una etiqueta o crear uno' }
  ],
  currentBill: {}
})

const actions = {
  async _createBill({ commit }, bill) {
    const { _id } = await API.createBill(bill)
    commit('pushInList', { list: 'listBills', content: Object.assign(bill, { _id, mode: 0 }) })
  },
  async _updateBill({ commit }, bill) {
    await API.updateBill(bill)
    commit('updateDataCurrentBill', { title: bill.title, tags: bill.tags })
    commit('dataBill', {title: bill.title, tags: bill.tags, _id: bill._id})
  },
  async _getCompleteBillList({ commit, state }) {
    if (state.listBills.length > 0) {
      return
    }
    const { bills } = await API.completeBillList()
    commit('fillInList', { list: 'listBills', content: bills })
  },
  async _changeBillMode({ commit }, { bill, mode, notificate = false, notification }) {
    await API.changeModeBill({ bill, mode, notificate, notification })
    commit('dataBill', {...bill, mode})
  },
  async _getFullBillContent({ commit }, { slug }) {
    const { bill } = await API.getFullContent({ slug })
    commit('dataCurrentBill', { newData: bill, slug })
  },
  async _formatAndSaveBillContent(_, { content, _id }) {
    content = content.replace(/<span.*?>(.*?)<\/span>/g, '$1').replace(/&nbsp;/g, ' ')
    const arrayContent = content.split(/<hr\/?>/)
    const finalContent = []
    arrayContent.forEach((pa, idx) => {
      if (pa) {
        finalContent.push({ con: pa, _id: idx })
      }
    })
    await API.saveBillContent({ _id, content: finalContent })
    return finalContent
  },
  async _createNewTag({ commit }, { tag }) {
    const { _id } = await API.createTag({ tag: tag.tag })
    commit('newTag', { _id, ...tag })
  },
  async _getTags({ commit, state }) {
    if (state.tags.length > 1) {
      return
    }
    const { tags = [] } = await API.getTags()
    const mTags = tags.map((tag, index) => {
      return {...tag, color: state.colors[index]}
    })
    commit('fillInList', { list: 'tags', content: mTags })
  },
  async _updateTag({ commit }, { _id, newText }) {
    await API.updateTag({ tagId: _id, tag: newText })
    commit('editTag', { _id, tag: newText })
  },
  async _removeTag({ commit }, { _id }) {
    await API.removeTag({ tagId: _id })
    commit('removeTag', _id)
  }
}

const mutations = {
  fillInList(state, { list, content }) {
    state[list] = content
  },
  pushInList(state, { list, content }) {
    state[list].push(content)
  },
  dataBill(state, newData) {
    const billId = newData._id
    if (!billId) {
      return
    }
    delete newData._id
    const indexBill = state.listBills.findIndex(bill => bill._id === billId)
    if (indexBill === -1) {
      return
    }
    for (const key in newData) {
      if (Object.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (state.listBills[indexBill][key] === undefined) {
          Vue.set(state.listBills[indexBill], key, element)
        }
        state.listBills[indexBill][key] = element
      }
    }
  },
  updateDataCurrentBill(state, newData) {
    state.currentBill = Object.assign(state.currentBill, newData)
  },
  dataCurrentBill(state, { newData, slug }) {
    const index = state.listBills.findIndex(bill => bill.slug === slug)
    state.currentBill = Object.assign(newData, index > -1 ? state.listBills[index] : {})
  },
  newTag(state, tag) {
    state.tags.push(tag)
  },
  editTag(state, tag) {
    const index = state.tags.findIndex(tg => tg._id === tag._id)
    if (index !== -1) {
      state.tags[index].tag = tag.tag
    }
  },
  removeTag(state, tagId) {
    const index = state.tags.findIndex(tg => tg._id === tagId)
    if (index !== -1) {
      state.tags.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
