export const removeHtml = (text, withSpace = false) => {
  if (withSpace) {
    return text.replace(/(<([^>]+)>)/ig, ' ').replace(/ +/g, ' ').trim()
  }
  return text.replace(/(<([^>]+)>)/ig, '').trim()
}

export const replaceAccents = (text) => {
  const mapReplace = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U'
  }
  return text.replace(/é|á|í|ó|ú|Á|É|Í|Ó|Ú/ig, matched => mapReplace[matched])
}

export const makeSearchableText = (text) => {
  if (!text) {
    return ''
  }
  text = text.toLowerCase()
  return replaceAccents(text)
}
