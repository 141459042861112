import Vue from 'vue'
import VueRouter from 'vue-router'
import Template from '../components/MainTemplate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login', name: 'login',
    component: () => import('../views/auth/Login.vue')
  },

  {
    path: '/',
    component: Template,
    children: [
      {
        path: '/', name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/profile', name: 'profile',
        component: () => import('../components/Profile.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/register', name: 'register',
        component: () => import('../views/auth/Register.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/jurisprudencia', name:'jurisprudencia',
        component: () => import('../views/Jurisprudencia.vue'),
        redirect: { name: 'listJuris' },
        meta: { requiresAuth: true },
        children: [
          {
            path: 'list/:ambito?', name: 'listJuris',
            component: () => import('../views/Jurisprudence/List.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'item/:ambito/:id/:entidad', name: 'itemJuris',
            component: () => import('../views/Jurisprudence/Item.vue'),
            meta: { requiresAuth: true },
            // beforeEnter: (to, from, next) => {
            //   store.dispatch('Temas/getTopic',to.params).then(res => {
            //     if (res) {
            //       next()
            //     }else{
            //       next('/no-resource-exists')
            //     }
            //   })
            // },
            children: [
              {
                path: 'exps/:subtopicid', name: 'expedientes',
                component: () => import('../views/Jurisprudence/Expedientes.vue'),
                meta: { requiresAuth: true }
              }
            ]
          }
        ]
      },
      {
        path: '/legislation',
        component: () => import('@/views/Legislation.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'general', name:'legislation',
            component: () => import('@/components/legislation/listBooks.vue'),
          },
          {
            path: 'shared', name: 'sharedLegis',
            component: () => import('@/components/legislation/listSharedCodes.vue')
          },
          {
            path: 'shared/groups', name: 'sharedLegisGroups',
            component: () => import('@/components/legislation/GroupsSharedLegislation.vue')
          }
        ]
      },
      {
        path: '/legislation/:id', name: 'book_code',
        component: () => import('@/components/legislation/book.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/users', name: 'Users',
        component: () => import ('@/views/user/index.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/books', name: 'books',
        component: () => import ('@/views/books/listBooks.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/bills', name: 'bills',
        component: () => import('@/views/bill/index.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/bill/:slug', name: 'in_bill',
        component: () => import('@/views/bill/_bill.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/admin-changes', name: 'admin-changes',
        component: () => import('@/views/admin/AdminOptionsPage.vue'),
        meta: { requiresAuth: true, superAdmin: true },
        children: [
          {
            path: 'changes-db', name: 'changes-db',
            component: () => import('@/views/admin/changes-db.vue'),
            meta: { requiresAuth: true, superAdmin: true }
          },
          {
            path: 'app-version', name: 'app-version',
            component: () => import('@/views/admin/AppVersionPage.vue'),
            meta: { requiresAuth: true, superAdmin: true }
          },
          {
            path: 'run-cronjobs', name: 'run-cronjobs',
            component: () => import('@/views/admin/RunCronJobs.vue'),
            meta: { requiresAuth: true, superAdmin: true }
          },
          {
            path: 'suscription-plans', name: 'sus-plans',
            component: () => import('@/views/admin/SuscriptionPlans.vue'),
            meta: { requiresAuth: true, superAdmin: true }
          },
        ]
      },
      {
        path: '/notification-admin', name: 'notification-admin',
        component: () => import('@/views/admin/notifications.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/admin-offers', name: 'admin-offers',
        component: () => import('@/views/offers/index.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/bank', name: 'bank',
        component: () => import('@/views/transfer_bank/index.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'voucher-attend', name: 'vouchers-attend',
            component: () => import('@/views/transfer_bank/VouchersAttend.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'information', name: 'bank-info',
            component: () => import('@/views/transfer_bank/BankInfo.vue'),
            meta: { requiresAuth: true }
          }
        ]
      },
    ]
  },
  {
    path: '*', name: '404',
    component: () => import('../views/error/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = Vue.prototype.$firebase.auth().currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const superAdmin = to.matched.some(record => record.meta.superAdmin)

  if (requiresAuth && !currentUser) next({name: 'login'})
  else if(!requiresAuth && currentUser) next({name: 'home'})
  else if (superAdmin && !Vue.prototype.$isSuperAdmin) next({name: 'home'})
  else next()
})

export default router
