import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'
// import Temas from './Temas'
import legislation from './Legislation'
import User from './User'
import Author from './modules/Author'
import JurisTopics from './modules/juris/Topics'
import JurisExpedients from './modules/juris/Expedients'
import Bill from './modules/Bill'
import Suscriptions from './modules/Suscriptions'
import axios from 'axios'

Vue.use(Vuex)
const inDev = process.env.NODE_ENV === 'development'

export default new Vuex.Store({
  state: {
    minVariantNavigation: false,
    filterSubTopic: undefined,
    titleAppBar: '',
    urlApiElastic: inDev ? 'http://localhost:5500' : 'https://apiv.abogher.com',
    filterSubTopicTxt: '',
    entityJuris: 'csj',
    ambitoJuris: 'penal',
    jurisAmbits: [
      { name: 'penal', id: 0 },
      { name: 'civil', id: 1 },
      { name: 'laboral', id: 2 },
      { name: 'administrativo', id: 3 }
    ],
    jurisEtities: [
      { name: 'csj', id: 0, text: 'C.S.J.' },
      { name: 'cc', id: 1, text: 'C.C.' },
      { name: 'cidh', id: 2, text: 'C.I.D.H' }
    ],
    rules: {
      required: v => !!v || 'Campo obligatorio.',
      nullable: v => !v,
      min: v => v.length >= 13 || 'Al menos 13 carácteres.',
      maxLength: (max = 10) => v => (!!v && v.length <= max) || `Un máximo de ${max} carácteres.`,
      minLength: (min = 5) => v => (!!v && v.length > min) || `Un minimo de ${min} carácteres.`,
      exeLength: (tl = 1) => v => (!!v && v.length === tl) || `Un total de ${tl} carácteres.`,
      email: v => /.+@.+\..+/.test(v) || 'El correo electrónico no es válido.',
      numNoZero: v => Number(v) !== 0 || 'No tiene que ser cero',
      minNumber (min) {
        return v => v > min || 'El numero es muy bajo'
      },
      numeric: v => /^\d+$/.test(v) || 'Tiene que ser un numero entero',
      numericFloat: v => /^\d+.?\d*$/.test(v) || 'Tiene que ser un numero real',
      slug: v => !(/[^A-Za-z0-9_-]/.test(v)) || 'Caractes inspereado',
      appVersion: v => /^\d+\.\d+\.\d+$/.test(v) || 'Mal formato de version',
      url: v => !v || /^(http|https):\/\/[^ "]+$/.test(v) || 'Tiene que ser una url valida',
    },
    user: {
      displayName: '',
      email: '',
      photoURL: '',
      uid: '',
      phone: '',
      profesionalData: {
        address: {},
      }
    },
    itemJuris: {},
    newExpTemp: {},
    entitySubTopics: []
  },
  getters: {
    ambitJurisByName: (state) => (name) => {
      return state.jurisAmbits.find(ambit  => ambit.name === name)
    },
    ambitJurisById: (state) => (id) => {
      return state.jurisAmbits.find(ambit  => ambit.id === id)
    },
    entityJurisByName: (state) => (name) => {
      return state.jurisEtities.find(entity => entity.name === name)
    },
    entityJurisById: (state) => (id) => {
      return state.jurisEtities.find(entity => entity.id === id)
    }
  },
  mutations: {
    initAuth (state, { user, otherDatauser }) {
      user = {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        uid: user.uid,
        phone: user.phoneNumber
      }
      Object.assign(state.user, otherDatauser, user)
    },
    logout(state){
      state.user = {}
    },

    changeItemJuris(state, item){
      state.itemJuris = item
    },

    changeEntity(state, enty){
      state.entityJuris = enty
    },

    changeAmbito(state, amb){
      state.ambitoJuris = amb
    },

    changeNewExpTemp(state, temp){
      state.newExpTemp = temp
    },

    mutateFilterSubTopic(state, newFilter){
      state.filterSubTopic = newFilter.id
      state.filterSubTopicTxt = newFilter.title
    },

    mutateEntitySubTopics(state, list){
      state.entitySubTopics = []
      state.entitySubTopics = list
    },
    updateItemSubtopic (state, { _id, subtopic }) {
      const index = state.entitySubTopics.findIndex(sub => sub._id === _id)
      if (index  !== -1) {
        Object.assign(state.entitySubTopics[index], subtopic)
      }
    },
    deleteItemSubtopic (state, _id) {
      const index = state.entitySubTopics.findIndex(sub => sub._id === _id)
      if (index !== -1) {
        state.entitySubTopics.splice(index, 1)
      }
    },
    addNewSubTopic(state, item){
      state.entitySubTopics.push(item)
    },
    mutateTitleAppBar(state, title){
      state.titleAppBar = title
    },
    minimizaNav (state, mini){
      state.minVariantNavigation = mini
    }
  },

  actions: {
    indexInElastic ({ state },{ id, body ,index}) {
      return new Promise(async (resolve, reject) => {
        try {
            axios.defaults.headers.common['Authorization'] = await Vue.prototype.$firebase.auth().currentUser.getIdToken(true)
            await axios.post(`${state.urlApiElastic}/api/indexdoc/${index}`,{
                id,
                body
            })
            resolve()
        } catch (error) {
          reject('Error de indexado en elastic! '+error.message)
        }
      })
    },
    deleteIndexElasticsearch ({state},{id, index}) {
      return new Promise(async (resolve, reject) => {
        try {
            axios.defaults.headers.common['Authorization'] = await Vue.prototype.$firebase.auth().currentUser.getIdToken(true)
            await axios.delete(`${state.urlApiElastic}/api/removedoc/${index}/${id}`)
            resolve()
        } catch (error) {
            reject(error)
        }
      })
    },
    async getTotalItemsInServerElastic ({ state }, amb) {
      try {
        axios.defaults.headers.common['Authorization'] = await Vue.prototype.$firebase.auth().currentUser.getIdToken(true)
        const res = await axios.get(`${state.urlApiElastic}/api/jurisprudencia/count/temas?amb=${amb}`)
        return res.data.count
      } catch (error) {
        console.error('error list juris')
      }
      return new Promise(async (resolve, reject) => {
        try {
            resolve(count.data.count)
        } catch (error) {
            reject('Total items | '+error.message,'error')
        }
      })
    },
    async initAuth ({ dispatch, commit }) {
      const user = await Vue.prototype.$firebase.auth().currentUser
      if (!user) return
      // TODO: get other data user from own API
      const otherDatauser = {}
      commit('initAuth', { user, otherDatauser })
      dispatch('setTokenToAxios')
      // dispatch('cycleSetTokenToAxios')
    },
    async setTokenToAxios (context) {
      const token = await Vue.prototype.$firebase.auth().currentUser.getIdToken(true)
      Vue.prototype.$http.defaults.headers.common['Authorization'] = token
      return token
    },
    cycleSetTokenToAxios ({ dispatch }) {
      setInterval(() => {
        dispatch('setTokenToAxios')
      }, 300000)
    }
  },

  modules: {
    // Temas,
    JurisTopics,
    JurisExpedients,
    legislation,
    User,
    Author,
    Bill,
    Suscriptions
  },
  strict: inDev,
  plugins: inDev ? [createLogger()] : []
})
